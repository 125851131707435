import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFirstTimeLogin: false,
  isUserRoleExecuted: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFirstTimeLogin(state, action) {
      // console.log('setFirstTimeLogin',action);
      state.isFirstTimeLogin = action.payload;
    },
    setUserRoleExecuted(state, action) {
      state.isUserRoleExecuted = action.payload;
    },
  },
});

const authSlice = {
  initialState,
  reducers: { [slice.name]: slice.reducer },
  actions: slice.actions,
  select: {
    isFirstTimeLogin: (state) => state[slice.name]?.isFirstTimeLogin ?? false,
    isUserRoleExecuted: (state) => state[slice.name]?.isUserRoleExecuted ?? false,
  },
  rememberedKeys: [],
};

export default authSlice;