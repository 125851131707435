import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import authorizerSlice from 'src/authorizer/authorizerSlice';
import { useState } from 'react';

import { m } from 'src/util/cssUtil';
import styles from 'src/components/adminPage/AdminPage.module.css';
import egainLogo from 'src/img/egain_ai_agent.svg';
import profileLogo from 'src/img/profile-circle.svg';
import dropDownIcon from 'src/img/dropdown_icon.svg'
import signOutIcon from 'src/img/signout_icon.svg';

export default function AdminHeader() {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  // const firstName = useSelector(authorizerSlice.select.firstName);
  const userName = useSelector(authorizerSlice.select.username);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // let firstLetter = '';
  // if (firstName !== null) {
  //   firstLetter = firstName.charAt(0);
  // }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSignOutClick = () => {
    window.location.href = process.env.REACT_APP_LOGOUT_URL;
  };

  const handleGoToAI = () => {
    const domain = sessionStorage.getItem('domain');
    window.location.href = `https://${domain}`;
  };

  return (
    <div className={m(styles.topBar, 'd-flex', 'align-items-center')}>
      <div
        className={m(
          styles.marginX,
          'd-flex',
          'justify-content-between',
          'align-items-center',
          'gx-0'
        )}
      >
        {/* This wrapper div is used so the logo can be aligned to the center without stretching*/}
        <div>
          <img src={egainLogo} className={styles.headerLogo} alt="eGain Logo" onClick={() => {
            const domain = sessionStorage.getItem('domain');
            window.location.href = `https://${domain}`;
          }} />
        </div>
        {userName !== null && (
          <div className={styles.profileLogo} style={{ position: 'relative', overflow: 'visible' }}>
            <div
              onClick={toggleDropdown}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <img src={profileLogo} style={{ width: '25px', marginRight: '5px' }} alt="Profile Logo" />
              <span>{userName?.length > 15 ? `${userName.slice(0, 15)}...` : userName}</span>
              <img
                src={dropDownIcon}
                alt="Dropdown"
                className={isDropdownOpen ? 'dropdownIcon[data-open="true"]' : 'dropdownIcon'}
              />
            </div>
            {isDropdownOpen && (
              <div className={styles.dropdownMenu}>
                <div className={styles.dropdownMenuItem}
                  onClick={handleSignOutClick}
                >
                  <img
                    src={signOutIcon}
                    className={styles.dropdownMenuItemLogo}
                    alt="Sign Out"
                  />
                  Sign Out
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
