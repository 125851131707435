import { lazy } from 'react';

import EntryPage from 'src/components/entryPage/EntryPage';
import LargeAdminPage from 'src/components/adminPage/LargeAdminPage';
import SmallAdminPage from 'src/components/adminPage/SmallAdminPage';
import LoginPage from 'src/components/loginPage/LoginPage';
import HomePage from 'src/pages/homepage/HomePage';
import Redirect from 'src/pages/redirect/Redirect';
import TinyPage from 'src/components/tinyPage/TinyPage';
import Root from 'src/pages/root/Root';
import { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import styles from 'src/components/tinyPage/TinyPage.module.css';

const NotFound = lazy(() =>
  import(
    /* webpackChunkName: "NotFound" */
    /* webpackPrefetch: true */
    'src/pages/notfound/NotFound'
  )
);

const DomainNotFound = lazy(() => import('src/pages/notfound/DomainNotFound'));

const Login = lazy(() =>
  import(
    /* webpackChunkName: "Login" */
    /* webpackPrefetch: true */
    'src/pages/login/Login'
  )
);

// const HomePage = lazy(
//   () =>
//     import(
//       /* webpackChunkName: "Login" */
//       /* webpackPrefetch: true */
//       'src/pages/homepage/HomePage'
//     )
// );

const SignUp = lazy(() =>
  import(
    /* webpackChunkName: "SignUp" */
    /* webpackPrefetch: true */
    'src/pages/signup/SignUp'
  )
);

const SignUpKnowledge = lazy(() =>
  import(
    /* webpackChunkName: "SignUpKnowledge" */
    /* webpackPrefetch: true */
    'src/pages/signupknowledge/SignUpKnowledge'
  )
);

const Confirm = lazy(() =>
  import(
    /* webpackChunkName: "Confirm" */
    /* webpackPrefetch: true */
    'src/pages/confirm/Confirm'
  )
);

const Subscription = lazy(() =>
  import(
    /* webpackChunkName: "Subscription" */
    /* webpackPrefetch: true */
    'src/pages/subscription/Subscription'
  )
);

const Pay = lazy(() =>
  import(
    /* webpackChunkName: "Pay" */
    /* webpackPrefetch: true */
    'src/pages/pay/Pay'
  )
);

const Optout = lazy(() =>
  import(
    /* webpackChunkName: "cancelSuccess" */
    /* webpackPrefetch: true */
    'src/pages/optOut/optOut'
  )
);

const Waiting = lazy(() =>
  import(
    /* webpackChunkName: "Waiting" */
    /* webpackPrefetch: true */
    'src/pages/waiting/Waiting'
  )
);

const MyAccount = lazy(() =>
  import(
    /* webpackChunkName: "MyAccount" */
    /* webpackPrefetch: true */
    'src/pages/myaccount/MyAccount'
  )
);

const Upgrade = lazy(() =>
  import(
    /* webpackChunkName: "Upgrade" */
    /* webpackPrefetch: true */
    'src/pages/upgrade/Upgrade'
  )
);

const Modify = lazy(() =>
  import(
    /* webpackChunkName: "Modify" */
    /* webpackPrefetch: true */
    'src/pages/modify/Modify'
  )
);

const ThankYou = lazy(() =>
  import(
    /* webpackChunkName: "ThankYou" */
    /* webpackPrefetch: true */
    'src/pages/thankyou/ThankYou'
  )
);

const AccountInfo = lazy(() =>
  import(
    /* webpackChunkName: "AccountInfo" */
    /* webpackPrefetch: true */
    'src/pages/accountInfo/AccountInfo'
  )
);

const Logout = lazy(() =>
  import(
    /* webpackChunkName: "Logout" */
    /* webpackPrefetch: true */
    'src/pages/logout/Logout'
  )
);

const VerifyEmail = lazy(() =>
  import(
    /* webpackChunkName: "VerifyEmail" */
    /* webpackPrefetch: true */
    'src/pages/verify-email/VerifyEmail'
  )
);

const routes = [
  {
    path: '/',
    errorElement: (
      <TinyPage>
        <NotFound />
      </TinyPage>
    ),
    element: <Root />,
    children: [
      {
        path: 'login',
        element: (
          <EntryPage mode="login">
            <Login />
          </EntryPage>
        )
      },
      {
        path: 'home',
        element: <HomePage />
      },
      {
        path: 'try',
        element: (
          <EntryPage mode="plansAndPricing">
            <SignUp />
          </EntryPage>
        )
      },
      {
        path: 'tryKnowledge',
        element: (
          <EntryPage mode="plansAndPricing">
            <SignUpKnowledge />
          </EntryPage>
        )
      },
      {
        path: 'buy',
        element: (
          <EntryPage mode="plansAndPricing">
            <SignUp />
          </EntryPage>
        )
      },
      {
        path: 'confirm',
        element: (
          <EntryPage mode="plansAndPricing">
            <Confirm />
          </EntryPage>
        )
      },
      {
        path: 'subscription',
        element: (
          <EntryPage mode="plansAndPricing">
            <Subscription />
          </EntryPage>
        )
      },
      {
        path: 'pay',
        element: (
          <EntryPage mode="plansAndPricing">
            <Pay />
          </EntryPage>
        )
      },
      {
        path: 'domainNotFound',
        element: (
          <TinyPage>
            <DomainNotFound />
          </TinyPage>
        )
      },
      {
        path: 'optout',
        element: (
          <EntryPage mode="thankYou">
            <Optout />
          </EntryPage>
        )
      },
      {
        path: 'waiting',
        element: (
          <EntryPage mode="thankYou">
            <Waiting />
          </EntryPage>
        )
      },
      {
        path: 'myaccount',
        element: (
          <LargeAdminPage>
            <MyAccount />
          </LargeAdminPage>
        )
      },
      {
        path: 'myaccount/redirect',
        element: <Redirect />
      },
      {
        path: 'myaccount/upgrade',
        element: (
          <LargeAdminPage>
            <Upgrade />
          </LargeAdminPage>
        )
      },
      {
        path: 'myaccount/modify',
        element: (
          <LargeAdminPage>
            <Modify />
          </LargeAdminPage>
        )
      },
      {
        path: 'myaccount/thankyou',
        element: (
          <SmallAdminPage>
            <ThankYou />
          </SmallAdminPage>
        )
      },
      {
        path: 'myaccount/accountInformation',
        element: (
          <LargeAdminPage>
            <AccountInfo />
          </LargeAdminPage>
        )
      },
      {
        path: 'verify-email',
        element: (
          <EntryPage mode="thankYou">
            <VerifyEmail />
          </EntryPage>
        )
      },
      {
        path: 'logout',
        element: (
          <TinyPage isLogout={true}>
            <Logout />
          </TinyPage>
        )
      }
    ]
  }
];

export default routes;
