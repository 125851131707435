import React from 'react';
import { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import AdminHeader from 'src/components/adminPage/AdminHeader';
import styles from 'src/components/adminPage/AdminPage.module.css';
import { m, mergeIf } from 'src/util/cssUtil';
import easyWithEgain from 'src/components/endInfo/easyWithEgain.svg';

const Notify = lazy(() => import('src/components/adminPage/notify/Notify'));

export default function LargeAdminPage({ children }) {

  return (
    <div className={m(styles.pageBox, 'd-flex', 'flex-column')}>
      <AdminHeader />
      <Suspense>
        <Notify />
      </Suspense>
      <div
        as="main"
        className={mergeIf(
          styles.largeContainer,
          'flex-grow-1',
          'd-flex',
          'flex-column',
          'my-1'
        )}
      >
        <Suspense>{children}</Suspense>
      </div>
      {/* <div className={m(styles.easyFooter)}> */}
        {/* <img
          src={easyWithEgain}
          className={styles.easyLogo}
          alt="easy with eGain"
        /> */}
      {/* </div> */}
    </div>
  );
}

LargeAdminPage.propTypes = {
  children: PropTypes.element.isRequired
};
