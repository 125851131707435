import { createSlice } from '@reduxjs/toolkit';
 
const initialState = {
  trial: {
    remainingTime: {
      days: null,
    },
    expiryDate: null,
  },
  production: {
    currentPeriodEndDate: null,
    endDateInUtc: null,
    customer: null,
    subscription: null,
    status: null,
    isPaymentFailure: false,
    subscriptionItems: [],
  },
  isTrialToProd: false, // New state for tracking trial to production conversion
  isBackfromAccountInfo: false, // New state for tracking back from account info
  initialItems: [],
};
 
const slice = createSlice({
  name: 'aboutSub',
  initialState,
  reducers: {
    loadSubscription(state, action) {
      const data = action.payload.body.data;
      state.trial = JSON.parse(JSON.stringify(initialState.trial));
      state.production = JSON.parse(JSON.stringify(initialState.production));
 
      if (data.expiryDate !== undefined) {
        state.trial.expiryDate = data.expiryDate;
        state.trial.remainingTime.days = data.remainingTime.days;
        return;
      }
 
      state.production.currentPeriodEndDate = data.currentPeriodEndDate;
      state.production.endDateInUtc = data?.endDateInUtc;
      state.production.customer = data.customerId;
      state.production.subscription = data.stripeSubscriptionId;
      state.production.status = data.status;
      state.isPaymentFailure = data.isPaymentFailure;
      state.production.subscriptionItems = data.subscriptionItems;
    },
    updateStatus(state, action) {
      state.production.status = action.payload.status;
    },
    clearTrial(state) {
      state.trial = initialState.trial;
    },
    setIsTrialToProd(state, action) { // New action to update isTrialToProd state
      state.isTrialToProd = action.payload;
    },
    setIsBackfromAccountInfo(state, action) { // New action to update isBackfromAccountInfo state
      state.isBackfromAccountInfo = action.payload;
    },
    setInitialItems(state, action) {
      state.initialItems = action.payload;
    },
  },
});
 
const aboutSubSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    trial: {
      remainingTime: {
        days: (state) => state[slice.name].trial.remainingTime.days,
      },
      expiryDate: (state) => state[slice.name].trial.expiryDate,
      trialItems: (state) => state[slice.name].trial.trialItems,
    },
    production: {
      currentPeriodEndDate: (state) => state[slice.name].production.currentPeriodEndDate,
      endDateInUtc: (state) => state[slice.name].production?.endDateInUtc,
      customer: (state) => state[slice.name].production.customer,
      subscription: (state) => state[slice.name].production.subscription,
      status: (state) => state[slice.name].production.status,
      subscriptionItems: (state) => state[slice.name].production.subscriptionItems,
      isPaymentFailure: (state) => state[slice.name].isPaymentFailure,
    },
    isTrialToProd: (state) => state[slice.name].isTrialToProd, // New selector for isTrialToProd
    isBackfromAccountInfo: (state) => state[slice.name].isBackfromAccountInfo, // New selector for isBackfromAccountInfo
    initialItems: (state) => state[slice.name].initialItems,
  },
  rememberedKeys: [],
};
 
export default aboutSubSlice;

