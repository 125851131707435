import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  aiAgents: 0,
  totalConversations: 0,
  resolvedConversations: 0,
  escalatedConversations: 0,
  abandonedConversations: 0,
};

const slice = createSlice({
  name: 'usage',
  initialState,
  reducers: {
    setAiAgents: (state, action) => {
      state.aiAgents = action.payload;
    },
    setTotalConversations: (state, action) => {
      state.totalConversations = action.payload;
    },
    setResolvedConversations: (state, action) => {
      state.resolvedConversations = action.payload;
    },
    setEscalatedConversations: (state, action) => {
      state.escalatedConversations = action.payload;
    },
    setAbandonedConversations: (state, action) => {
      state.abandonedConversations = action.payload;
    },
    setUsageDetails: (state, action) => {
      console.log('setting usageData', state, action);
      Object.assign(state, action.payload);
    },
  },
});

const usageSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    aiAgents: (state) => state[slice.name].aiAgents,
    totalConversations: (state) => state[slice.name].totalConversations,
    resolvedConversations: (state) => state[slice.name].resolvedConversations,
    escalatedConversations: (state) => state[slice.name].escalatedConversations,
    abandonedConversations: (state) => state[slice.name].abandonedConversations,
  },
  rememberedKeys: [slice.name],
};
export default usageSlice;