import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userName: null,
  password: null,
};

const slice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    setUserName(state, action) {
      state.userName = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    clearCredentials(state) {
      state.userName = initialState.userName;
      state.password = initialState.password;
    },
  },
});

const credentialsSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    userName: (state) => state[slice.name].userName,
    password: (state) => state[slice.name].password,
  },
  rememberedKeys: [slice.name],
};

export default credentialsSlice;